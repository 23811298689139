import request from '../httpRequest'
/**
 * api授权接口
 * 
 * 必须headers ，且包含以下参数
 *    接入凭证：appKey
 *    时间戳：timestamp
 *    加密签名：sign  签名规则：md5(orgCode) + md5(appKey) + md5(app_secret_key) + md5(timestamp)
 */

// 授权登陆
export function login(data,headers) {
  return request({
    url: '/auth/login',
    method: 'post',
    data: data,
    headers: headers
  })
}

// 刷新token
export function refreshToken(data,headers) {
  return request({
    url: '/auth/refreshToken',
    method: 'put',
    data: data,
    headers: headers
  })
}

// 授权注册用户
export function register(data,headers) {
  return request({
    url: '/auth/register',
    method: 'post',
    data: data,
    headers: headers
  })
}

// 授权修改密码
export function modifyPwd(data,headers) {
  return request({
    url: '/auth/modifyPwd',
    method: 'put',
    data: data,
    headers: headers
  })
}

// 授权更新用户信息
export function update(data,headers) {
  return request({
    url: '/auth/update',
    method: 'put',
    data: data,
    headers: headers
  })
}

// 授权获取用户信息
export function getUserInfo(key,headers) {
  return request({
    url: '/auth/getUserInfo/' + key,
    method: 'get',
    headers: headers
  })
}
  