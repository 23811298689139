<template>
	<div class="icp">
		Copyright © 2024 {{ author }} All Rights Reserved.
		<!-- <img class="icp-icon" src="../../assets/image/icp_logo.png">
		<a target="_blank"  href="https://beian.miit.gov.cn/">粤ICP备2022140558号-1</a> -->
	</div>
</template>

<script>
export default {
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      owner: process.env.VUE_APP_OWNER,
      ownerSUb: process.env.VUE_APP_OWNER_SUB,
      icpNo: process.env.VUE_APP_ICP,
      icpUrl: process.env.VUE_APP_ICP_URL,
      author: process.env.VUE_APP_AUTHOR,
      authorShort: process.env.VUE_APP_AUTHOR_SHORT,
      authorUrl: process.env.VUE_APP_AUTHOR_URL
	}
  }
}
</script>

<style lang="scss">
	.icp {
		position: fixed;
		text-align: center;
		bottom: 20px;
		margin: 0 auto;
		width: 100%;
		color: #9a9a9a;
		font-size: 14px;
		
		.icp-icon {
			width: 20px;
			height: 20px;
			vertical-align: bottom;
		}
	}
</style>