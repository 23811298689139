<template>
	<div class="login-view">
		<div class="login-content">
			<el-form ref="loginForm" :model="loginForm" 
				status-icon 
				label-width="60px"
				class="login-form"
			>
				<div class="login-brand">
					<img v-if="logoUrl" :src="logoUrl" class="logo" />
					{{ env.owner }} - {{ env.titleSub }}
					<div style="color: orangered; font-size: 20px;">共享屏幕</div>
				</div>
			</el-form>
			<!-- 接口调试模式：未携带businessKey参数时为 -->
			<el-button style="margin: 5px;" @click="openServer()">开启SocketServer</el-button>
			<el-button style="margin: 5px;" @click="openClient()">开启SockerClient </el-button>
		</div>
	</div>

</template>

<script>
	import logoImg from '@/assets/logo/logo.png'
	export default {
		name: "shareScreen",
		data() {
			return {
				logoUrl: logoImg,
				env: {
					title: process.env.VUE_APP_NAME,
					titleSub: process.env.VUE_APP_TITLE,
					author: process.env.VUE_APP_AUTHOR,
					authorUrl: process.env.VUE_APP_AUTHOR_URL,
					owner: process.env.VUE_APP_OWNER,
				},
				loginForm: { },
			};
		},
		methods: {
			openServer(){
				this.$http({
					url: "/ScreenTeacher/30001",
					method: 'get',
				}).then((data) => {
					console.log('res>>>>',data)
				})
			}
		},
		mounted() { 

		},
	}
</script>

<style scoped lang="scss">
	.login-view {
		width: 100%;
		height: 100%;
		background: #E8F2FF;
		background-size: cover;
		box-sizing: border-box;


		.login-content {
			position: relative;
			display: flex;
			justify-content: space-around;
			align-items: flex-start;
			padding: 10%;
			// padding: 5%;

			.login-intro {
				flex: 1;
				padding: 40px;
				max-width: 600px;

				.login-title {
					text-align: center;
					font-weight: 600;
					font-size: 30px;
				}

				.login-icons {
					display: flex;
					align-items: center;

					.login-icon {
						padding-left: 5px;
					}
				}
			}

			.login-form {
				height: 340px;
				width: 400px;
				padding: 30px;
				background: white;
				opacity: 0.9;
				box-shadow: 0px 0px 1px #ccc;
				border-radius: 3%;
				overflow: hidden;
				border: 1px solid #ccc;

				.login-brand {
					line-height: 50px;
					margin: 30px 0 40px 0;
					font-size: 22px;
					font-weight: 600;
					letter-spacing: 2px;
					text-transform: uppercase;
					text-align: center;
					vertical-align: middle;
					.logo{
						height: 50px;
						margin-bottom: -15px;
					}
				}
				// .register {
				// 	display: flex;
				// 	flex-direction: row-reverse;
				// 	line-height: 40px;
				// 	text-align: left;
				// 	padding-left: 20px;
				// }
				.loading{
					margin-top: 25%;
					width: 100%;
					text-align: center;
					div{
						width: 100%;
					}
				}
				.auth-msg{
					margin-top: 25%;
					width: 100%;
					text-align: center;
					color: #fa3534;
					.icon{
						font-size: 19px;
					}
				}
			}
		}
	}
</style>