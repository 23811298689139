<template>
	<div class="login-view">
		<div class="login-content">
			<el-form ref="loginForm" :model="loginForm" 
				status-icon 
				label-width="60px"
				class="login-form"
			>
				<div class="login-brand">
					<img v-if="logoUrl" :src="logoUrl" class="logo" />
					{{ env.owner }} - {{ env.titleSub }}
					<div style="color: orangered; font-size: 20px;">开放接口调试模式</div>
				</div>
				<el-form-item label="终端" prop="userName" v-show="false">
					<el-input type="terminal" v-model="loginForm.terminal" autocomplete="off"></el-input>
				</el-form-item>
				<div class="loading" v-if="authStatus.code">
					<div v-loading="true" element-loading-text="授权中"></div>
				</div>
				<div v-else-if="authStatus.msg" class="auth-msg">
					<i class="el-icon-warning icon"></i>
					{{ authStatus.msg }}
				</div>
			</el-form>
			<!-- 接口调试模式：未携带businessKey参数时为 -->
			<div v-if="!loginForm.businessKey"
				style="
					position: relative;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					align-items: flex-start;
					padding-bottom: 100px;
					margin: -10% 0 0 0;
				"
			>
				<div style="
						position: relative;
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;
						margin: 20px 0;
					"
				>
					<el-button style="margin: 5px;" :type="authType == 'login' ? 'warning' : 'primary'" @click="setAuthType('login')">授权登录</el-button>
					<el-button style="margin: 5px;" :type="authType == 'reg' ? 'warning' : 'primary'" @click="setAuthType('reg')">授权注册</el-button>
					<el-button style="margin: 5px;" :type="authType == 'pwd' ? 'warning' : 'primary'" @click="setAuthType('pwd')">授权修改密码</el-button>
					<el-button style="margin: 5px;" :type="authType == 'update' ? 'warning' : 'primary'" @click="setAuthType('update')">授权更新用户</el-button>
					<el-button style="margin: 5px;" :type="authType == 'userInfo' ? 'warning' : 'primary'" @click="setAuthType('userInfo')">获取用户信息</el-button>
					<el-button style="margin: 5px;" :type="authType == 'retoken' ? 'warning' : 'primary'" @click="setAuthType('retoken')">刷新token</el-button>
				</div>
				<div style="
						margin: 20px 0px;
						width: 100%;
					"
				>
					<div v-if="authHeaders.length > 0" style="font-weight: bold;color: orangered;padding-left:30px;">
						Headers参数：
					</div>
					<el-row v-for="item in authHeaders" :key="item.id"  style="margin: 10px 0;">
						<el-col :span="10" style="text-align: right;">
							{{ item.title }}
							{{ item.key }}：
						</el-col>
						<el-col :span="14">
							<el-input v-model="item.value" :placeholder="'填写' + (item.title || item.key)"></el-input>
						</el-col>
					</el-row>
					<div  v-if="authForm.length > 0" style="font-weight: bold;color: orangered;padding-left:30px;">
						业务参数：
					</div>
					<el-row v-for="item in authForm" :key="item.id"  style="margin: 10px 0;">
						<el-col :span="10" style="text-align: right;">
							{{ item.title }}
							{{ item.key }}：
						</el-col>
						<el-col :span="14">
							<el-input v-model="item.value" :placeholder="'填写' + (item.title || item.key)"></el-input>
						</el-col>
					</el-row>
					<el-row style="text-align: center; margin: 20px;" v-if="(authForm.length + authHeaders.length) > 0">
						<el-button type="success" @click="submitAuthForm()">提交</el-button>
					</el-row>
					<div  v-if="resResult.length > 0" style="font-weight: bold;color: orangered;padding-left:30px;">
						响应结果：
					</div>
					<div style="height: 300px; overflow-y: auto">
						<el-row v-for="item in resResult" :key="item.id" style="margin: 10px 0;">
							<el-col :span="10" style="text-align: right;color:#606266;">
								{{ item.key }}：
							</el-col>
							<el-col :span="14" style="color: #333">
								{{ item.value }}
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
		</div>
		<!-- 版权信息组件 -->
		<icp></icp>
	</div>

</template>

<script>
	import md5 from 'md5'
	import logoImg from '@/assets/logo/logo.png'
	import Icp from '../components/common/Icp.vue'
	import * as authApi from '@/api/request/auth.js'
	export default {
		name: "login",
		components: {
			Icp
		},
		data() {
			return {
				logoUrl: logoImg,
				env: {
					title: process.env.VUE_APP_NAME,
					titleSub: process.env.VUE_APP_TITLE,
					author: process.env.VUE_APP_AUTHOR,
					authorUrl: process.env.VUE_APP_AUTHOR_URL,
					owner: process.env.VUE_APP_OWNER,
				},
				loginForm: {
					terminal: this.$enums.TERMINAL_TYPE.WEB,
					appKey: '1',
					sign: '1',
					timestamp: '1',
					userName: '',
					password: ''
				},
				authStatus: {
					code: 0,	// 0:完成（或失败），1:默认（或处理中）
					msg: ''		// 失败信息
				},
				// headers参数
				authHeaders: [],
				// 业务参数
				authForm: [],
				// 当前接口类型
				authType: '',
				// 响应参数
				resResult: []
			};
		},
		methods: {
			// 授权登录提交
			submitLogin() {
				authApi.login(this.madeFormObj(),this.madeHeaderObj()).then((data) => { 
					if(data){
						this.resetAuthStatus();
						// 保存token
						sessionStorage.setItem("accessToken", data.accessToken);
						sessionStorage.setItem("refreshToken", data.refreshToken);
						this.$message.success("授权成功");
						this.$router.push("/home/chat");
					}else{
						this.authStatus = {
							code: 0, 
							msg: '授权失败，' + (data && data.msg ? data.msg : '未知错误')
						};
					}
				}).catch((err) => {
					this.authStatus = {
							code: 0, 
							msg: '授权失败，' + (err.message ? err.message : '未知错误')
						};
				})
			},

			// 设置调试接口类型
			setAuthType(val){
				this.authType = val;
				this.resResult = [];

				this.authHeaders = [];
				let timestamp = new Date().getTime();
				this.authHeaders.push({title: '接入凭证', key: 'appKey', value: md5(process.env.VUE_APP_NAME)});
				this.authHeaders.push({title: '时间戳', key: 'timestamp', value: timestamp});
				this.authHeaders.push({
					title: '密签', key: 'sign', 
					value: 	md5(timestamp)
							+ md5(process.env.VUE_APP_TITLE)
							+ md5(process.env.VUE_APP_AUTHOR)
							+ md5(process.env.VUE_APP_OWNER)
				});

				this.authForm = [];
				this.authForm.push({title: '业务键值', key: 'businessKey', value: ''});

				switch(val){
					case 'login':
						this.authForm.push({title: '终端类型（0:web 1:app 2:pc）', key: 'terminal', value: '2'});
						break;
					case 'reg':
						this.authForm.push({title: '用户名', key: 'userName', value: ''});
						this.authForm.push({title: '初始密码', key: 'password', value: ''});
						this.authForm.push({title: '名称（昵称）', key: 'nickName', value: ''});
						this.authForm.push({title: '性别(0：男，1：女)', key: 'sex', value: ''});
						this.authForm.push({title: '头像', key: 'head_image', value: ''});
						this.authForm.push({title: '头像缩略图', key: 'head_image_thumb', value: ''});
						this.authForm.push({title: '个人简介', key: 'signature', value: ''});
						break;
					case 'pwd':
						this.authForm.push({title: '新密码', key: 'newPassword', value: ''});
						break;
					case 'update':
						this.authForm.push({title: '用户名', key: 'userName', value: ''});
						this.authForm.push({title: '名称（昵称）', key: 'nickName', value: ''});
						this.authForm.push({title: '性别(0：男，1：女)', key: 'sex', value: ''});
						this.authForm.push({title: '头像', key: 'head_image', value: ''});
						this.authForm.push({title: '头像缩略图', key: 'head_image_thumb', value: ''});
						this.authForm.push({title: '个人简介', key: 'signature', value: ''});
						break;
					case 'retoken':
						this.authHeaders.push({key: 'refreshToken', value: ''});
						this.authForm = [];
						break;
					case 'userInfo':
						break;
				}
			},
			submitAuthForm(){
				this.authStatus = {
						code: 1, 
						msg: ''
					};
				switch(this.authType){
					case 'login':
						this.submitLogin()
						break;
					case 'reg':
						authApi.register(this.madeFormObj(),this.madeHeaderObj()).then((data) => {
							this.$message.success("注册成功");
							this.loadResult(data);
						}).catch((err) => {
							this.authStatus = {
									code: 0, 
									msg: '注册失败，' + (err.message ? err.message : '未知错误')
								};
						})
						break;
					case 'pwd':
						authApi.modifyPwd(this.madeFormObj(),this.madeHeaderObj()).then((data) => {
							this.$message.success("密码修改成功");
							this.loadResult(data);
						}).catch((err) => {
							this.authStatus = {
									code: 0, 
									msg: '密码修改失败，' + (err.message ? err.message : '未知错误')
								};
						})
						break;
					case 'update':
						authApi.update(this.madeFormObj(),this.madeHeaderObj()).then((data) => {
							this.$message.success("用户更新成功");
							this.loadResult(data);
						}).catch((err) => {
							this.authStatus = {
									code: 0, 
									msg: '用户更新失败，' + (err.message ? err.message : '未知错误')
								};
						})
						break;
					case 'retoken':
						authApi.refreshToken(this.madeFormObj(),this.madeHeaderObj()).then((data) => {
							if(data){
								this.$message.success("token刷新成功");
								this.loadResult(data);
							}else{
								this.authStatus = {
									code: 0, 
									msg: '授权失败，' + (data && data.msg ? data.msg : '未知错误')
								};
							}
						}).catch((err) => {
							this.authStatus = {
									code: 0, 
									msg: 'token刷新失败，' + (err.message ? err.message : '未知错误')
								};
						})
						break;
					case 'userInfo':
						authApi.getUserInfo(
							this.madeFormObj().businessKey,
							this.madeHeaderObj()
						).then((data) => {
							this.loadResult(data);
						}).catch((err) => {
							this.authStatus = {
									code: 0, 
									msg: '获取失败，' + (err.message ? err.message : '未知错误')
								};
						})
						break;
				}
			},
			resetAuthStatus(){
				this.authStatus = {
						code: 0, 
						msg: ''
					};
			},
			madeFormObj(){
				let form = {};
				this.authForm.map(item =>{
					form[item.key] = item.value;
				})
				return form;
			},
			madeHeaderObj(){
				let obj = {};
				this.authHeaders.map(item =>{
					obj[item.key] = item.value;
				})
				return obj;
			},
			loadResult(data){
				this.resetAuthStatus()
				this.resResult = [];
				if(data){
					Object.keys(data).forEach((key) => {
						this.resResult.push({
							key: key,
							value: data[key]
						})
					});
				}else{
					this.resResult.push({
						key: 'data',
						value: 'null'
					})
				}
			},
		},
		mounted() {
			this.loginForm = {};
			// 若带参数，则自动登录
			if(this.$route.query.businessKey){
				// TODO: 待完善
				this.loginForm.appKey = this.$route.query.appKey;
				this.loginForm.sign = this.$route.query.sign;
				this.loginForm.timestamp = this.$route.query.timestamp;
				this.loginForm.businessKey = this.$route.query.businessKey;

				this.authHeaders = [];
				let timestamp = new Date().getTime();
				this.authHeaders.push({title: '接入凭证', key: 'appKey', value: md5(process.env.VUE_APP_NAME)});
				this.authHeaders.push({title: '时间戳', key: 'timestamp',  value:timestamp});
				this.authHeaders.push({
					title: '密签', key: 'sign', 
					value: 	md5(timestamp)
							+ md5(process.env.VUE_APP_TITLE)
							+ md5(process.env.VUE_APP_AUTHOR)
							+ md5(process.env.VUE_APP_OWNER)
				});

				this.authForm = [];
				this.authForm.push({title: '业务键值', key: 'businessKey', value: this.loginForm.businessKey});
				this.authForm.push({title: '终端类型', key: 'terminal', value:'2'});
				// 提交登录
				this.submitLogin();
			}
		}
	}
</script>

<style scoped lang="scss">
	.login-view {
		width: 100%;
		height: 100%;
		background: #E8F2FF;
		background-size: cover;
		box-sizing: border-box;


		.login-content {
			position: relative;
			display: flex;
			justify-content: space-around;
			align-items: flex-start;
			padding: 10%;
			// padding: 5%;

			.login-intro {
				flex: 1;
				padding: 40px;
				max-width: 600px;

				.login-title {
					text-align: center;
					font-weight: 600;
					font-size: 30px;
				}

				.login-icons {
					display: flex;
					align-items: center;

					.login-icon {
						padding-left: 5px;
					}
				}
			}

			.login-form {
				height: 340px;
				width: 400px;
				padding: 30px;
				background: white;
				opacity: 0.9;
				box-shadow: 0px 0px 1px #ccc;
				border-radius: 3%;
				overflow: hidden;
				border: 1px solid #ccc;

				.login-brand {
					line-height: 50px;
					margin: 30px 0 40px 0;
					font-size: 22px;
					font-weight: 600;
					letter-spacing: 2px;
					text-transform: uppercase;
					text-align: center;
					vertical-align: middle;
					.logo{
						height: 50px;
						margin-bottom: -15px;
					}
				}
				// .register {
				// 	display: flex;
				// 	flex-direction: row-reverse;
				// 	line-height: 40px;
				// 	text-align: left;
				// 	padding-left: 20px;
				// }
				.loading{
					margin-top: 25%;
					width: 100%;
					text-align: center;
					div{
						width: 100%;
					}
				}
				.auth-msg{
					margin-top: 25%;
					width: 100%;
					text-align: center;
					color: #fa3534;
					.icon{
						font-size: 19px;
					}
				}
			}
		}
	}
</style>